// @mui
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

HomeWidgetSummary.propTypes = {
  color: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function HomeWidgetSummary({
  title,
  to,
  color = "primary",
  sx,
  justifyContent,
  ...other
}) {
  return (
    <Link
      to={to}
      style={{
        display: "flex",
        textDecoration: "none",
        justifyContent: justifyContent,
      }}
    >
      <Card
        sx={{
          display: "flex",
          // width: "100%",
          minWidth: "306.6px",
          height: "306.6px",
          py: 5,
          boxShadow: 0,
          textAlign: "center",
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "0.2s",

          "&:hover": {
            opacity: "0.85",
            transform: "scale(1.03)",
          },

          "&:active": {
            opacity: "0.85",
            transform: "scale(0.97)",
          },
          ...sx,
        }}
        {...other}
      >
        <Typography
          variant="subtitle2"
          sx={{ opacity: 0.72, fontSize: "1.1rem" }}
        >
          {title}
        </Typography>
      </Card>
    </Link>
  );
}
