import { useCallback, useState } from "react";
import api from "../api";

export function useNotes() {
    const [notes, setNotes] = useState(null)

    const fetchNote = useCallback(async (userId) => {
        try {
            const { data } = await api.get(`/notes/list`);

            if (data) {
                setNotes(data.data);
            }
        } catch (error) {
            console.log('Erro ao obter notas')
        }
    }, [])

    const saveNote = useCallback(async (newNote) => {
        try {
            const { data } = await api.post(`/notes/create`, newNote);

            if (data) {
                setNotes(notes => [data.data, ...notes])
            }
        } catch (error) {
            console.log('Erro ao salvar nota')
        }
    })

    const doneNote = useCallback(async (note) => {
        try {
            const { data } = await api.put('/notes/update', note);

            if (data.data.finish === 1) {
                const otherNotes = notes.filter(nt => nt.id !== note.id);
                setNotes([...otherNotes, note]);
            } else if (data.data.finish === 0) {
                const otherNotes = notes.filter(nt => nt.id!== note.id);
                setNotes([note, ...otherNotes]);
                return;
            }

        } catch (error) {
            console.log('Erro ao finalizar nota')
        }
    })

    const deleteNote = useCallback(async (dataForm) => {
        try {
            const { data } = await api.post('/notes/delete', dataForm);

        } catch (error) {
            console.log('Erro ao deletar nota')
        }
    })

    return {notes, setNotes, saveNote, fetchNote, doneNote, deleteNote }
}