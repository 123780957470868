import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(7)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/assets/images/avatars/avatar_12.jpg`,
  name: sample([
    'Loja Shalazan',
    'Loja do Centro',
    'Loja no Bairro',
    'Loja Facilits',
    'Loja Outro Mundo',
    'Loja Teste',
    'Loja Abracadabra',
  ]),
  company: sample([
    'Volkswagen Gol',
    'Volkswagen Voyage',
    'Ford Fiesta',
    'Hyundai HB20',
    'Chevrolet Onix',
    'Toyota Corolla',
    'Volkswagen Nivus',
  ]),
  isVerified: sample([
    'FOQ-4A98',
    'QOX-4T49',
    'SAP-5D20',
    'CKQ-7B71',
    'QRI-2V52',
    'XMG-3U23',
    'PQD-6X44',
  ]),
  status: sample([
    'CR_9182',
    'CR_7123',
    'CA_12D8',
    'CO_1K9A',
    'CR_19A!',
    'CL_0091',
    'CI_O9O!'
  ]),
  role: sample([
    '2018',
    '2017',
    '2014',
    '2022',
    '2019',
    '2021',
    '2015',
  ]),
  nome: sample([
    'João Henrique',
    'Kenji',
    'Sora',
    'Rafael',
  ]),
  active: sample([
    '0',
    '1'
  ])
}));

export default users;
