import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useDashboard } from '../hooks/Services/useDashboard';
import { useState, useEffect } from 'react';
import { AppContext } from '../hooks/AppContext';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { userName } = useContext(AppContext);
  const { result, isLoading, fetchData } = useDashboard();

  const theme = useTheme();

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const chartData = result.data.cars; 

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Olá, {userName.split(' ')[0]}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            {/* Comentário: Loading spinner */}
            <AppWidgetSummary title="Respondidos" total={result.data.answered || 0} color="info" icon={'fluent:vehicle-car-16-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {/* Comentário: Loading spinner */}
            <AppWidgetSummary title="SLA de Resposta" total={parseFloat(result.data.slaStatus4_5) || 0} color="warning" icon={'ic:round-pending-actions'} />
          </Grid>
{/* 
          <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="Resumo"
              chartData={[
                { label: 'Pendentes', value: result.data.pending || 0 },
                { label: 'Aceitos', value: result.data.accepted || 0 },
                { label: 'Rejeitados', value: result.data.rejected || 0 },
                { label: 'Finalizados', value: result.data.finished || 0 },
              ]}
              chartColors={[
                theme.palette.warning.main,
                theme.palette.success.main,
                theme.palette.error.main,
                theme.palette.info.main,
              ]}
              loading={isLoading}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={6}>
            <AppTasks
              title="Tarefas"
              list={[]}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
