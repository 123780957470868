import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
// Pages
import BlogPage from "./pages/BlogPage";
import SurveysPage from "./pages/SurveysPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import StoreList from "./pages/ProductsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import NewTicket from "./pages/NewTicket";
import ViewSurveys from "./pages/ViewSurveys";
import ReplySurvey from "./pages/ReplySurvey";
// Secure route
import PrivateRoute from "./hooks/Token/PrivateRoute";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Profile from "./pages/Profile";
import DashboardHomePage from "./pages/Home";
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        {
          path: "home",
          element: <PrivateRoute children={<DashboardHomePage />} />,
          index: true,
        },
        {
          path: "app",
          element: <PrivateRoute children={<DashboardAppPage />} />,
        },
        {
          path: "ticket",
          element: <PrivateRoute children={<SurveysPage />} />,
        },
        // { path: 'new-ticket', element: <NewTicket /> },
        {
          path: "view-ticket",
          element: <PrivateRoute children={<ViewSurveys />} />,
        },
        {
          path: "reply-ticket",
          element: <PrivateRoute children={<ReplySurvey />} />,
        },
        { path: "profile", element: <PrivateRoute children={<Profile />} /> },
        // { path: 'store', element: <StoreList /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "reset/password",
      element: <ResetPassword />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
