import React, { useContext, useRef, forwardRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Label from "src/components/label/Label";
import { useProfile } from "../hooks/Services/useProfile";
import { AppContext } from "../hooks/AppContext";
import placeholderImage from "../img/newImparLogoP.png";
import { saveProfilePicInLocalStorageFile } from "src/utils/formatImage";

export default function Profile() {
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);
  const { updateProfile, isLoading } = useProfile();
  const { userName, userMail, userStoreName, profilePicName } =
    useContext(AppContext);
  const [formValues, setFormValues] = useState({
    name: "Nome completo",
    email: "Email",
    phone: "Telefone",
    unit: "Unidade",
  });
  const fileInputRef = useRef(null);

  const handleChange = async (newFile) => {
    const picBase64 = await saveProfilePicInLocalStorageFile(
      newFile.target.files[0],
      false
    );
    setFileBase64(picBase64);
    setFile(newFile);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card sx={{ py: 10, px: 3 }}>
          {isEdit && (
            <Label
              color={"success"}
              sx={{
                textTransform: "uppercase",
                position: "absolute",
                top: 24,
                right: 24,
              }}
            >
              {"Active"}
            </Label>
          )}
          <Box sx={{ mb: 5 }}>
            <Avatar
              onClick={handleImageClick}
              style={{
                margin: "0 auto",
                height: "150px",
                width: "150px",
                cursor: "pointer",
              }}
              src={
                fileBase64
                  ? fileBase64
                  : profilePicName
                  ? profilePicName
                  : placeholderImage
              }
              alt="photoURL"
            />
            <Button className="d-none">
              Upload File
              <input
                onChange={handleChange}
                ref={fileInputRef}
                type="file"
                hidden
              />
            </Button>
            <Typography
              variant="caption"
              sx={{
                mt: 2,
                mx: "auto",
                display: "block",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              Defina uma foto de perfil.
              <br />A foto deve estar no formato .JPG, .JPEG ou .PNG
            </Typography>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={8}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <TextField
                fullWidth
                disabled
                label="Nome Completo"
                value={userName}
                error={false}
              />
              <TextField
                fullWidth
                disabled
                value={userMail}
                label="Email"
                error={false}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 3, sm: 2 }}
            >
              <TextField fullWidth disabled label="Telefone" error={false} />
              <TextField
                select
                fullWidth
                disabled
                label="Unidade"
                defaultValue="unit"
                SelectProps={{ native: true }}
                error={false}
              >
                <option value="unit">{userStoreName}</option>
              </TextField>
            </Stack>

            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                onClick={() => {
                  updateProfile({ formValues, profilePic: { file } });
                }}
              >
                Salvar alterações
              </LoadingButton>
            </Box>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
