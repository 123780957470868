import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";

export const useProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const updateProfile = useCallback(async (dataProfile) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("formProfile", JSON.stringify(dataProfile.formValues));

      if (
        dataProfile.profilePic &&
        typeof dataProfile.profilePic === "object"
      ) {
        const groupKey = Object.keys(dataProfile.profilePic)[0];

        if (groupKey) {
          const item = dataProfile.profilePic[groupKey];
          formData.append("profilePic", item);
        }
      }

      const { data } = await api.data("/dealer/profile/update", formData);

        if (data) {
          navigate('/dashboard/app');
        } else {
          // setResult({ data: [] });
          // setMessage("Erro data");
          console.log('erro')
        }
    } catch {
      //   setResult({
      //     data: {},
      //     status: 500,
      //     message: "Sem conexão com o servidor!",
      //   });
      //   setMessage("Erro servidor");
      console.log("erro");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    updateProfile,
    isLoading,
  };
};
