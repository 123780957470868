import { Helmet } from "react-helmet-async";
import { useState } from "react";
// @mui
import {
  Card,
  Stack,
  Checkbox,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Paper,
} from "@mui/material";
// components
import { useLocation } from "react-router-dom";
import img from "../img/image.jpg";

import Scrollbar from "../components/scrollbar";
import TimeLine from "src/components/timeline";

// ----------------------------------------------------------------------

export default function ViewSurveys() {
  const location = useLocation();
  const props = location.state;

  const vehiclePhotosRefArray = ["vehiclePics", "dealerVehiclePics"];

  const vehicleDocsPhotosRefArray = ["docVehicle", "dealerDocVehicle"];

  return (
    <>
      <Helmet>
        <title>Detalhes</title>
      </Helmet>

      <Container>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <TimeLine status={props?.status} />
          <Typography variant="h4" gutterBottom className="w-100 mt-5">
            Detalhes
          </Typography>
        </Stack>

        <Card className="p-2">
          <Scrollbar>
            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Marca"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.brand || ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Modelo"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.model || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Ano"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.year || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Cor"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.color || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Placa"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.plate || ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="outlined-basic"
                      label="Chassi"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.chassis || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Dados Adicionais"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.additional || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="outlined-basic"
                      label="Identificação"
                      disabled
                      variant="outlined"
                      className="w-100"
                      value={props.cod || ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className="text-center">
                    <FormControlLabel
                      disabled
                      control={<Checkbox checked={props.armored} />}
                      label="Veículo Blindado"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Foto do veículo
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {props.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehiclePhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => {
                        return (
                          <Grid item xs={6} md={3} key={index}>
                            <img
                              width={200}
                              src={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              alt={photo.ref}
                              title={photo.ref}
                            />
                          </Grid>
                        );
                      })
                  ) : (
                    <Grid item xs={12}>
                      <Paper sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          Sem fotos anexadas
                        </Typography>
                        <Typography variant="body2">
                          Nenhuma foto do veículo anexada
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>

            <Card sx={{ boxShadow: 3 }} className="mb-4">
              <Typography variant="h6" gutterBottom>
                Foto da documentação
              </Typography>
              <Box className="d-flex mb-1 mt-3">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {props.photos ? (
                    props.photos
                      .filter((photo) =>
                        vehicleDocsPhotosRefArray.includes(photo.ref)
                      )
                      .map((photo, index) => {
                        return (
                          <Grid item xs={4} md={4} key={index}>
                            <img
                              width={200}
                              src={`${process.env.REACT_APP_API_ENDPOINT}/upload/${photo.foto}`}
                              alt={photo.ref}
                              title={photo.ref}
                            />
                          </Grid>
                        );
                      })
                  ) : (
                    <Grid item xs={12}>
                      <Paper sx={{ textAlign: "center" }}>
                        <Typography variant="h6" paragraph>
                          Sem fotos anexadas
                        </Typography>
                        <Typography variant="body2">
                          Nenhuma foto dos documentos anexada
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
