import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

export function useDashboard() {
    const [result, setResult] = useState({ data: { cars: [] } });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await api.get('/dealer/info/dashboard');
            if (data) {
                setResult(data);
            } else {
                setResult({ data: { cars: [] } });
                navigate('/login');
            }
        } catch {
            setResult({ data: { cars: [] } });
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    return { result, fetchData, isLoading };
}
