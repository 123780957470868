import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  Box,
  Button,
  Container,
  Typography,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { object, string, reach } from "yup";
import AlertSnackbar from "../../components/alert/AlertSnackbar";
import { useLogin } from "../../hooks/Services/useLogin";

export default function ResetPassword() {
  const { resetPassword, message, setMessage } = useLogin();
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [sent, setSent] = useState(false);

  const schema = object().shape({
    email: string().required('Email necessário').email('Email inválido')
  })

  const handleAlert = () => {
    setShowAlert(false);
    setMessage(prev => { 
        return {...prev, show: false};
    });
  }

  const handleSubmit = () => {
    reach(schema, 'email').validate(email, { abortEarly: false })
      .then((valid) => { resetPassword(email); setSent(true) })
      .catch(error => {
        setMessage({ message: error.errors[0], status: 500, show: true });
      })
  }

  useEffect(() => {
    if (message.message !== '' && message.show) {
        setShowAlert(true);
    }
  }, [message, setMessage])

  return (
    <Card className="d-flex align-items-center justify-content-center h-100">
      <Container>
        <Box sx={{ maxWidth: 480, mx: "auto" }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                Esqueceu sua senha?
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 5 }}>
                Digite o endereço de e-mail associado à sua conta e enviaremos
                um link para redefinir sua senha.
              </Typography>

              <Stack spacing={3}>
                <TextField fullWidth type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Solicitar
                </LoadingButton>
              </Stack>

              <Button
                fullWidth
                size="large"
                component={RouterLink}
                to={'/login'}
                sx={{ mt: 1 }}
              >
                Voltar
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              {/* <SentIcon sx={{ mb: 5, mx: "auto", height: 160 }} /> */}

              <Typography variant="h3" gutterBottom>
                Pedido enviado com sucesso
              </Typography>
              <Typography>
                Enviamos uma senha temporária para&nbsp;
                <p><strong>{email}</strong></p>
                <br />
                Por favor verifique seu email.
              </Typography>

              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                to={'/login'}
                sx={{ mt: 5 }}
                onClick={() => setSent(false)}
              >
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Container>
      <AlertSnackbar openAlert={showAlert} duration={2500} status={message.status} message={message.message} onClose={handleAlert} />
    </Card>
  );
}
