export const saveProfilePicInLocalStorage = async (url) => {
  const response = await fetch(url);
    
    const blob = await response.blob();
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        localStorage.setItem('Kinto_dealer_profilePic', reader.result);
        resolve(reader.result);
      };
      
      reader.onerror = () => {
        reject('Error reading the image');
      };
      
      reader.readAsDataURL(blob);
    });
  };

  export function saveProfilePicInLocalStorageFile(file, save) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        const base64String = reader.result;
        resolve(base64String);
        {save && localStorage.setItem('Kinto_dealer_profilePic', base64String)}
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }