import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";

export const useSurveys = () => {
  const [result, setResult] = useState({ data: [] });
  const [params, setParams] = useState({ page: 0, rowsPerPage: 20 });
  const [message, setMessage] = useState({ message: "", show: false });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const { page, rowsPerPage } = params;
      const { data, total } = await api.get(
        `/dealer/list/surveys/${page}/${rowsPerPage}`
      );

      if (data) {
        setResult(data, total);
      } else {
        setResult({ data: [], status: 400, message: "Erro." });
      }
    } catch {
      setResult({
        data: [],
        status: 500,
        message: "Sem conexão com o servidor!",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const searchSurvey = useCallback(
    async (dataSearch) => {
      setIsLoading(true);

      try {
        const queryParams = {
          params: {
            all: dataSearch[0].all,
          },
        };
        const { data } = await api.get(
          `/dealer/list/surveys/${params.page}/${params.rowsPerPage}`,
          queryParams
        );

        if (data) {
          setResult(data);
        } else {
          setResult({ data: [], status: 400, message: "Erro." });
          navigate("/login");
        }
      } catch (error) {
        setResult({
          data: [],
          status: 500,
          message: "Sem conexão com o servidor!",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const acceptSurvey = useCallback(async (idSurvey) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(`/dealer/survey/accept/${idSurvey}`);

      if (data) {
        setMessage(data.message);
        setParams({ page: 0, rowsPerPage: 20 });
      } else {
        setMessage("Opps, ocorreu um erro");
      }
    } catch {
      setMessage("Sem conexão com o servidor!");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const refuseSurvey = useCallback(async (idSurvey) => {
    setIsLoading(true);

    try {
      const { data } = await api.post(`/dealer/survey/refuse/${idSurvey}`);

      if (data) {
        setMessage(data.message);
        setParams({ page: 0, rowsPerPage: 20 });
      } else {
        setMessage("Opps, ocorreu um erro");
      }
    } catch {
      setMessage("Sem conexão com o servidor!");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const replySurvey = useCallback(async (dataReply) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("aboutVeh", JSON.stringify(dataReply.formValues));
      formData.append("survey", JSON.stringify(dataReply.survey));

      Object.keys(dataReply.fotos).forEach((groupKey) => {
        const group = dataReply.fotos[groupKey];

        if (groupKey === "fileDocs") {
          const getArray = group.docVehicle || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "dealerDocVehicle");
          });
        }

        if (groupKey === "vehiclePics") {
          const getArray = group.vehiclePics || [];
          getArray.forEach((file) => {
            formData.append("arquivo", file);
            formData.append("ref", "dealerVehiclePics");
          });
        }
      });

      const { data } = await api.data("/dealer/survey/reply", formData);

      if (data) {
        setMessage({ message: data.message, status: 200, show: true });
        setResult(data);
        setTimeout(() => {
          navigate("/dashboard/ticket");
        }, 500);
      } else {
        setResult({ data: [] });
        setMessage("Erro data");
      }
    } catch {
      setResult({
        data: {},
        status: 500,
        message: "Sem conexão com o servidor!",
      });
      setMessage("Erro servidor");
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    result,
    fetchData,
    isLoading,
    message,
    setMessage,
    params,
    setParams,
    searchSurvey,
    replySurvey,
    acceptSurvey,
    refuseSurvey,
  };
};
