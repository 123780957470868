import { Helmet } from "react-helmet-async";
import { useEffect, useState, useContext } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  CircularProgress,
} from "@mui/material";
// components
import { useNavigate } from "react-router-dom";
import { getComparator, applySortFilter } from "src/utils/sortFilterComparator";
import { useSurveys } from "../hooks/Services/useSurveys";
import { AppContext } from "../hooks/AppContext";
import AlertSnackbar from "../components/alert/AlertSnackbar";

import Label from "../components/label";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
import SurveySkeleton from "../components/skeleton/SurveySkeleton";
import MessageModal from "../components/messageModal";
import Kanban from "../components/kanban";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "plate", label: "Placa", alignRight: false },
  { id: "brand", label: "Marca", alignRight: false },
  { id: "model", label: "Modelo", alignRight: false },
  { id: "color", label: "Cor", alignRight: false },
  { id: "year", label: "Ano", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function SurveysPage() {
  const { userId } = useContext(AppContext);

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);

  const {
    result,
    params,
    searchSurvey,
    setParams,
    acceptSurvey,
    refuseSurvey,
    isLoading,
  } = useSurveys();

  const [surveys, setSurveys] = useState([]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("desc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("id");

  const [filterPlacaValue, setFilterPlacaValue] = useState("");

  const [filterStatusValue, setFilterStatusValue] = useState({
    label: "",
    value: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [rselected, setRSelected] = useState("");

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const filterOptions = [
    { id: "plate", label: "Placa" },
    { id: 0, label: "Pendente" },
    { id: 1, label: "Aceito" },
    { id: 2, label: "Rejeitado" },
  ];

  const [filterAllValue, setFilterAllValue] = useState("");

  const searchFields = [
    {
      title: "Pesquisar",
      apiField: "all",
      type: "text",
      onChange: (e) => setFilterAllValue(e.target.value),
      inputValue: filterAllValue,
    },
  ];
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";

    if (isDesc && orderBy === property) {
      setOrderBy("id");
      setOrder("desc");
    } else if (orderBy === property) {
      setOrder("desc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = surveys.map((n) => n.plate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setParams({ ...params, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setParams({ ...params, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const filteredSurveys = applySortFilter(
    surveys,
    getComparator(order, orderBy)
  );

  const isNotFound = !surveys.length && !isLoading;

  useEffect(() => {
    setSurveys(result.data);
  }, [result]);

  const resetFilters = () => {
    setPage(0);
    setFilterPlacaValue("");
    setFilterStatusValue({ label: "", value: "" });
    setParams({ ...params, page: 0 });
  };

  return (
    <>
      <Helmet>
        <title>Vistorias</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Vistorias
          </Typography>
          {/* <Button onClick={() => { navigate('/dashboard/new-ticket') }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Nova vistoria
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            searchFunction={searchSurvey}
            resetFilters={resetFilters}
            searchFields={searchFields}
          />

          <Kanban datas={filteredSurveys} />

          {/* <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={surveys.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {surveys.length !== 0 ? (
                    filteredSurveys.map((row) => {
                      console.log("row", row);
                      const {
                        id,
                        plate,
                        brand,
                        model,
                        color,
                        year,
                        status,
                        returnSurveyMessage,
                      } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              {plate}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">{brand}</TableCell>

                          <TableCell align="left">{model}</TableCell>

                          <TableCell align="left">{color}</TableCell>

                          <TableCell align="left">{year}</TableCell>

                          <TableCell align="left">
                            <Label
                              color={
                                returnSurveyMessage?.length > 0
                                  ? "error"
                                  : status === 0
                                  ? "default"
                                  : status === 2
                                  ? "error"
                                  : status === 3
                                  ? "error"
                                  : status === 4
                                  ? "primary"
                                  : "success"
                              }
                            >
                              {status === 0
                                ? "Pendente"
                                : status === 1
                                ? "Aceito"
                                : status === 2
                                ? "Rejeitado"
                                : status === 3
                                ? "Cancelado"
                                : status === 4
                                ? "Respondido"
                                : status === 5
                                ? "Finalizando..."
                                : status === 6
                                ? "Finalizado"
                                : "Erro"}
                            </Label>
                          </TableCell>

                          {
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) => {
                                  handleOpenMenu(e);
                                  setRSelected(row);
                                }}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })
                  ) : isNotFound &&
                    (filterPlacaValue !== "" ||
                      filterStatusValue.value !== "") ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não encontrado
                          </Typography>
                          <Typography variant="body2">
                            Nenhum resultado para &nbsp;
                            <strong>
                              &quot;
                              {`Placa: ${filterPlacaValue} Status: ${filterStatusValue.label}`}
                              &quot;
                            </strong>
                            .
                            <br /> Tente verificar se há erros de digitação ou
                            usar palavras completas.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : isNotFound &&
                    filterPlacaValue === "" &&
                    filterStatusValue.value === "" ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: "center" }}>
                          <Typography variant="h6" paragraph>
                            Não há registros
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      <SurveySkeleton numberOfRows={7} />
                      <SurveySkeleton numberOfRows={7} />
                      <SurveySkeleton numberOfRows={7} />
                      <SurveySkeleton numberOfRows={7} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar> */}

          <TablePagination
            count={result.total ?? 0}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Resultado por página:"
            component="div"
            sx={{
              "& .MuiTablePagination-selectLabel": {
                marginTop: "15px",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: "15px",
              },
            }}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/view-ticket", { state: rselected });
          }}
        >
          <Iconify icon={"majesticons:open"} sx={{ mr: 2 }} />
          Abrir
        </MenuItem>

        {rselected.status === 0 && (
          <MenuItem
            onClick={() => {
              if (rselected.userId !== null && userId !== rselected.userId) {
                setMessage("Você não pode aceitar essa vistoria");
                setError(true);
                handleCloseMenu();
              } else {
                acceptSurvey(rselected.id);
                handleCloseMenu();
              }
            }}
          >
            <Iconify icon={"fe:check"} color={"green"} sx={{ mr: 2 }} />
            Aceitar
          </MenuItem>
        )}

        {rselected.status === 0 && rselected.userId === userId && (
          <MenuItem
            onClick={() => {
              refuseSurvey(rselected.id);
              handleCloseMenu();
            }}
          >
            <Iconify icon={"ph:x-bold"} color={"red"} sx={{ mr: 2 }} />
            Recusar
          </MenuItem>
        )}

        {rselected.status === 1 && (
          <MenuItem
            onClick={() => {
              if (rselected.status >= 2) {
                setMessage("Você não pode mais responder essa vistoria");
                setError(true);
                handleCloseMenu();
              } else {
                if (rselected.status === 0) {
                  setMessage("Primeiro você precisa aceitar a vistoria");
                  setError(true);
                  handleCloseMenu();
                } else if (rselected.userId !== userId) {
                  setMessage(
                    "Somente o usuário que aceitou a vistoria pode responder"
                  );
                  setError(true);
                  handleCloseMenu();
                } else {
                  navigate("/dashboard/reply-ticket", { state: rselected });
                }
              }
            }}
          >
            <Iconify icon={"gridicons:reply"} sx={{ mr: 2 }} />
            Responder
          </MenuItem>
        )}

        {rselected?.returnSurveyMessage?.length > 0 && (
          <MenuItem onClick={handleOpenMessageModal}>
            <Iconify icon={"tabler:message-filled"} sx={{ mr: 2 }} />
            Mensagem
          </MenuItem>
        )}
      </Popover>

      {/* <MessageModal
        open={openMessageModal}
        handleClose={handleCloseMessageModal}
        message={rselected?.returnSurveyMessage}
      /> */}

      <AlertSnackbar
        openAlert={error}
        duration={2500}
        message={message}
        status={500}
        onClose={() => setError(false)}
      />

      <Modal
        open={isLoading}
        className="d-flex justify-content-center align-items-center"
      >
        <CircularProgress color="inherit" />
      </Modal>
    </>
  );
}
