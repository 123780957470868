import { useCallback, useContext, useState } from 'react';
import api from '../api';
import { AppContext } from '../AppContext';

export function useLogin() {
    const [result, setResult] = useState(null);
    const [message, setMessage] = useState({ message: '', status: 0, show: false });
    const { setProfilePic, setUserMail } = useContext(AppContext);

    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loginUser = useCallback(async (userData) => {
        setIsLoading(true);

        try {
            const { data } = await api.post(`/login/user`, { ...userData, permission: 'USER' });

            if (data) {
                setResult(data);
                setMessage(data.message);
                setStatus(data.status);
                setProfilePic(data.data.profilePic)
                setUserMail(userData.email)
                localStorage.setItem('Token_Kinto_Loja', data.token)
            } else {
                setResult({});
                setMessage('Credenciais inválidas ou usuário inativo!');
                setStatus(400);
            }             
        } catch {
            setResult({});
            setStatus(500);
            setMessage('Sem conexão com o servidor!');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const resetPassword = useCallback(async (userEmail) => {
        setIsLoading(true);

        const params = {
            email: userEmail
        }

        try {
            const { data } = await api.post(`/login/recovery`, params);

            if (data) {
                setMessage({ message: data.message, status: 200, show: true });
            } else {
                setMessage({ message: 'Erro ao solicitar nova senha', status: 500, show: true });
            }             
        } catch {
            setMessage({ message: 'Erro de conexão com o servidor', status: 500, show: true });
        } finally {
            setIsLoading(false);
        }
    }, []);

    return { loginUser, result, isLoading, message, setMessage, status, resetPassword };
}