import { useContext } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Container, Typography, Box } from "@mui/material";

import { AppContext } from "../hooks/AppContext";
import HomeWidgetSummary from "src/components/Home/HomeWidgetSummary";
// ----------------------------------------------------------------------

export default function DashboardHomePage() {
  const { userName } = useContext(AppContext);

  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Box
        width="100%"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Grid spacing={1} container xs={6} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: 4 }}>
              Olá, {userName.split(" ")[0]}
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <HomeWidgetSummary
              to="/dashboard/app"
              title="DASHBOARD"
              color="success"
              justifyContent="flex-start"
            />
          </Grid>

          <Grid item xs={3.5}>
            <HomeWidgetSummary
              to="/dashboard/ticket"
              title="VISTORIAS"
              color="primary"
              justifyContent="flex-end"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
