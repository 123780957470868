import { Box, Grid, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  borderRadius: "20px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MessageModal = ({ open = false, handleClose, message }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>{message?.[0]?.message || "-"}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default MessageModal;
