import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";

const steps = [
  "Pendente",
  "Aceito",
  "Em auditoria",
  "Etapa final",
  "Finalizado",
];

export default function TimeLine(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  if(props?.status === 3){
    steps.push('Cancelado')
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={
          props?.status === 0
            ? 0
            : props?.status === 1
            ? 1
            : props?.status === 4
            ? 2
            : props?.status === 5
            ? 3
            : props?.status === 6
            ? 5
            : 6
        }
      >
        {steps.map((label, index) => {
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Auditoria finalizada!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
        </React.Fragment>
      )}
    </Box>
  );
}
