import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { saveProfilePicInLocalStorage } from '../utils/formatImage';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userName, setUserName] = useState('');
    const [userMail, setUserMail] = useState('')
    const [userStoreName, setUserStoreName] = useState('');
    const [userId, setUserId] = useState(0);
    const [profilePic, setProfilePic] = useState('');
    const navigate = useNavigate();
    const profilePicName = localStorage.getItem('Kinto_dealer_profilePic');

    const updateProfileInfo = useCallback(async () => {
        const token = localStorage.getItem('Token_Kinto_Loja');

        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserName(decoded.name);
                setUserStoreName(decoded.storeName);
                setUserMail(decoded.mail)
                setUserId(decoded.id);

                if (decoded.pic !== null && !profilePicName) {
                    setProfilePic(await saveProfilePicInLocalStorage(`${process.env.REACT_APP_API_PROFILE_PIC}/${decoded.pic}`));
                }
            } catch {
                navigate('/login');
            }
        }
    }, [navigate]);

    useEffect(() => {
        updateProfileInfo();
    }, [updateProfileInfo]);
    
    useEffect(() => {
        window.addEventListener('tokenUpdated', updateProfileInfo);
        return () => {
            window.removeEventListener('tokenUpdated', updateProfileInfo);
        };
    }, [updateProfileInfo]);

    return (
        <AppContext.Provider value={{ userName, userStoreName, userId, profilePic, setProfilePic, profilePicName, userMail, setUserMail }}>
            {children}
        </AppContext.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
}